import '../css/glider.min.css';
import '../scss/main.scss';
import './modal.js';
import './lazy-image.js';
import './leaveModal.js';
import './scroll.js';
import './contact.js';


let slideMain = null;
// Start slider
window.addEventListener('load', function(){
  slideMain = new Glider(document.querySelector('.glider'), {
    slidesToShow: 1,
    dots: '#dots',
    draggable: true,
    arrows: {
      prev: '.glider-prev',
      next: '.glider-next'
    }
  })
})

function refreshGlider() {
  slideMain.refresh(true);
}

document.addEventListener('startAGliderRefresh', () => {
  refreshGlider();
});


// document.querySelector('.footer__contact').setAttribute('href', 'mailto:contato@conteudobox.com.br');

// import './loading.js';
