document.querySelectorAll('a[href*="#"]').forEach(link => {
  link.addEventListener('click', event => {
    const href = link.getAttribute('href');

    if(href !== '#') {
      event.preventDefault();
      document.querySelector(href).scrollIntoView({
        behavior: 'smooth'
      })
    }
  });
})


window.onscroll = function() {scrollFunction()};

function scrollFunction() {
  if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
    document.querySelector(".header").classList.add('header--scrolling');
  } else {
    document.querySelector(".header").classList.remove('header--scrolling');
  }
}
