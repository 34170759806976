let grecaptchaToken = null;

function showResult(text) {
  document.querySelector('#result').innerHTML = text;
}

function handleClick($event) {
  $event.preventDefault();
  document.querySelector('#send_button').disabled = true;
  getAToken().then(() => {
    if(grecaptchaToken) {
      var name = document.querySelector('.modal__container__content .name').value;
      var phone = document.querySelector('.modal__container__content .phone').value;
      var email = document.querySelector('.modal__container__content .email').value;
      var desc = document.querySelector('.modal__container__content .desc').value;

      if(name && email && desc) {
        var data = {
            name: name,
            phone: phone,
            email: email,
            desc: desc,
            'g-recaptcha-response': grecaptchaToken
        };

        fetch('https://10p4xbl15f.execute-api.sa-east-1.amazonaws.com/contact', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'post',
            body: JSON.stringify(data)
        })
        .then(response => response.json())
        .then(text => {
          openModal('modal-success');
        })
        .catch(error => showResult(error))
        .finally(() => document.querySelector('#send_button').disabled = false);
      } else {
        showResult('Você precisa preencher todos os campos obrigatórios!')
      }
    }
  });
}

function getAToken() {
  return new Promise((resolve, reject) => {
    grecaptcha.ready(function() {
      grecaptcha.execute('6LeWC4gaAAAAALb2a2omQTn4cEw5qreDdOF4af1Q', {action: 'submit'})
      .then(function(token) {
        grecaptchaToken = token;
        resolve();
      });
    });
  })
}

document.querySelector('#send_button').addEventListener('click', ($event) => { handleClick($event) });
