const modalButtons = document.querySelectorAll('[open-modal]');
const modalCloseButtons = document.querySelectorAll('[close-modal]');

modalButtons.forEach((element) => {
  element.addEventListener('click', ($event) => {
    $event.preventDefault();
    const modalName = element.getAttribute('open-modal');
    openModal(modalName);
  });
});

modalCloseButtons.forEach((element) => {
  element.addEventListener('click', ($event) => {
    if(element.getAttribute('href') && element.getAttribute('href') === '#') {
      $event.preventDefault();
    }
    document.querySelectorAll('.modal').forEach(element => element.classList.remove('modal--show'));
  });
});

function closeAllModals() {
  document.querySelectorAll('.modal').forEach(element => element.classList.remove('modal--show'));
}


function openModal(modalName) {
  closeAllModals();
  document.querySelector(`#${modalName}`).classList.toggle('modal--show');
}

window.openModal = openModal;
window.closeAllModals = closeAllModals;
