let images = [...document.querySelectorAll("[loading]")];
let inAdvance = 100;

function lazyLoading() {
  images.forEach(image => {
    if((image.offsetTop < window.innerHeight + window.pageYOffset + inAdvance) && !image.getAttribute("src")) {
      image.setAttribute('src', image.dataset.src);
    }
  });
}

function init() {
  lazyLoading();

  setTimeout(lazyLoading, 1000);

  window.addEventListener('scroll', window.throttle(lazyLoading, 50));
  window.addEventListener('resize', window.throttle(lazyLoading, 50));
}


init();
